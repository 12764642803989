<template>
    <footer class="footer">
      <!-- <div class="footer-section">
        <h3>联系我们</h3>
        <p>xxx@gmail.com</p>
        <p>+86-xxxxx</p>
        <p>中国</p>
        <div class="social-icons">
          <a href="#"><i class="fab fa-facebook-f"></i></a>
          <a href="#"><i class="fab fa-linkedin-in"></i></a>
          <a href="#"><i class="fab fa-twitter"></i></a>
          <a href="#"><i class="fab fa-github"></i></a>
        </div>
      </div> -->
      <div class="footer-section">
        <h3>更多产品</h3>
        <p>不想刷题</p>
      </div>
      <div class="footer-section">
        <h3>关于我们</h3>
        <p>QQ群:970665763</p>
      </div>
      <!-- <div class="footer-section">
        <h3>获取APP</h3>
        <p>不想刷题</p>
      </div> -->
      <div class="footer-bottom">
        <p>&copy; 2024. All rights reserved.</p>
        <p>沪ICP备2024069788号-1</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FooterBar"
  };
  </script>
  
  <style scoped>
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f9f9f9;
    border-top: 1px solid #eaeaea;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .footer-section p {
    margin: 5px 0;
    color: #555;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #555;
    font-size: 1.2em;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    color: #000;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #eaeaea;
  }
  
  </style>
  