<template>
  <div class="home">
    <div class="advertisement">
      <div class="advertisement-content">
        <div class="advertisement-text">
          <div class="advertisement-title-container">
            <span class="bracket left-bracket">『</span>
            <span class="advertisement-title">不想刷题</span>
            <span class="bracket right-bracket">』</span>
          </div>
          <span class="advertisement-description">
            “不想刷题”是为考研学生设计开发的无纸化刷题网站，推荐使用ipad打开。与市面上的各种刷题小程序不同，“不想刷题”的使用方式与真实课本类似，学生可以直接在线打草稿，实时保存草稿。网站内置多个专业题库且仍在更新，后续功能敬请期待。点击右上角登录开始刷题吧！
          </span>
          <button class="free-button" @click="toBankList">免费试用</button>
        </div>
      </div>
      <img class="advertisement-top-image" src="@/assets/logo/logo.png" alt="Ink Master Logo" />
    </div>
    <div class="description-1">
      <span class="description-1-content">『 直接书写，无边界草稿，<br>答案浮窗方便对照 』</span>
      <img src="@/assets/imgs/draft-shot.svg" class="description-1-img"></img>
    </div>
    <div class="description-2">
      <span class="description-2-content">『 多端多设备同步，云端存储 』</span>
      <img src="@/assets/imgs/platform-logo.png" class="description-1-img"></img>
      <!--
      <div class="description-2-table">
        <div class="row">
          <div class="column">
            <img src="@/assets/imgs/ios.svg" alt="ios" class="device"></img>
            <img src="@/assets/imgs/android.svg" alt="android" class="device"></img>
          </div>
          <div class="column">
            <img src="@/assets/imgs/windows.svg" alt="windows" class="device"></img>
            <img src="@/assets/imgs/harmony.svg" alt="harmony" class="device-harmony"></img>
          </div>
        </div>
      </div>
      -->
    </div>
    <div class="description-1">
      <span class="description-1-content">『 超多题库，持续更新 』</span>
      <img src="@/assets/imgs/question-bank.svg" class="description-1-img"></img>
    </div>
    <div class="description-2">
      <span class="description-2-content">『 梦校Logo，伴你刷题 』</span>
      <el-carousel :interval="5000" arrow="always" height="800px" indicator-position="outside">
        <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
          <img :src="item.src" :alt="item.alt" class="description-1-img"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="description-2">
      <span class="description-1-content">『 客制化稿纸，想稿就搞 』</span>
      <img src="@/assets/imgs/custom-draft.svg" class="description-1-img"></img>
    </div>
    <div class="plans">
      <h2>网站不同版本区别</h2>
      <p>您可以选择您需要的版本</p>
      <div class="plan-options" v-if="ifLoaded">
        <div class="plan free" v-if="!isLoggedIn">
          <h3>游客版</h3>
          <p class="price">¥ 0 / 月</p>
          <button :disabled="!isLoggedIn">正在使用</button>
          <ul>
            <li><svg class="icon check" aria-hidden="true"><use xlink:href="#icon-zhengque"></use></svg> 在线打草稿刷题</li>
            <li><svg class="icon check" aria-hidden="true"><use xlink:href="#icon-zhengque"></use></svg> 无限量电子稿纸，打草稿不受边界</li>
            <li><svg class="icon error" aria-hidden="true"><use xlink:href="#icon-cuowu"></use></svg> 云端存储刷题进度，可多端同步进度，随时随地刷题</li>
            <li><svg class="icon error" aria-hidden="true"><use xlink:href="#icon-cuowu"></use></svg> 永久保留你的草稿</li>
            <li>
              <svg class="icon error" aria-hidden="true"><use xlink:href="#icon-cuowu"></use></svg> 更多优质题库，一旦购买，永久使用
              <div class="tooltip">
                <svg class="icon info" aria-hidden="true"><use xlink:href="#icon-bangzhu"></use></svg>
                <span class="tooltiptext">题库支持按需另购</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="plan premium" :class="{ 'logged-in': isLoggedIn }">
          <div class="most-popular">Most Popular ✨</div>
          <h3>登录版</h3>
          <p class="price">¥ 0 / 月</p>
          <button @click="toLogin" :disabled="isLoggedIn">{{ isLoggedIn ? '正在使用' : '去登录' }}</button>
          <ul>
            <li><svg class="icon check" aria-hidden="true"><use xlink:href="#icon-zhengque"></use></svg> 在线打草稿刷题</li>
            <li><svg class="icon check" aria-hidden="true"><use xlink:href="#icon-zhengque"></use></svg> 无限量电子稿纸，打草稿不受边界</li>
            <li><svg class="icon check" aria-hidden="true"><use xlink:href="#icon-zhengque"></use></svg> 云端存储刷题进度，可多端同步进度，随时随地刷题</li>
            <li><svg class="icon check" aria-hidden="true"><use xlink:href="#icon-zhengque"></use></svg> 永久保留你的草稿</li>
            <li>
              <svg class="icon check" aria-hidden="true"><use xlink:href="#icon-zhengque"></use></svg> 更多优质题库，一旦购买，永久使用
              <div class="tooltip">
                <svg class="icon info" aria-hidden="true"><use xlink:href="#icon-bangzhu"></use></svg>
                <span class="tooltiptext">题库支持按需另购</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <FooterBar />
  </div>
</template>

<script>
import api from '@/api';
import FooterBar from '@/components/FooterBar.vue';
import { ElCarousel, ElCarouselItem } from 'element-plus';

export default {
  name: 'HomeView',
  components: {
    FooterBar,
    ElCarousel,
    ElCarouselItem
  },
  data() {
    return {
      isLoggedIn: false,
      ifLoaded: false,
      carouselItems: [
        { src: require("@/assets/imgs/school-logo1.png"), alt: "School Logo 1" },
        // { src: require("@/assets/imgs/school-logo2.svg"), alt: "School Logo 2" },
        // { src: require("@/assets/imgs/school-logo.svg"), alt: "School Logo 3" }
      ]
    }
  },
  async mounted() {
    await this.checkLoginStatus();
    this.ifLoaded = true;
  },
  methods: {
    // 跳转到登录页面
    toLogin() {
      if (!this.isLoggedIn) {
        this.$router.push('/login');
      }
    },
    toBankList(){
      this.$router.push('/bank-list');
    },
    async checkLoginStatus() {
      // 判断用户是否登录，先获取token，如果token不存在则未登录，否则尝试获取用户信息，如果获取用户信息失败则未登录
      var token = localStorage.getItem('token');
      if (token) {
        try {
          const userInfoRes = await api.userInfo();
          if (userInfoRes.status === 200) {
            if (userInfoRes.data.code === 0) {
              this.isLoggedIn = true;
            } else {
              this.isLoggedIn = false;
              // 跳转到登录页面
              this.$message({
                message: '请先登录',
                type: 'warning'
              });
            }
          } else {
            this.isLoggedIn = false;
          }
        } catch (error) {
          console.log(error);
          this.isLoggedIn = false;
        }
      } else {
        this.isLoggedIn = false;
      }
    },
  }
}
</script>

<style>
.home {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: absolute;
  top: 10%;
  left: 0;
  background-color: #F4F5F6;
}

.row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.device{
  width: 80%;
}

.device-harmony{
  position: relative;
  width: 80%;
}

.advertisement {
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: white;
  margin-top: 0; /* 修改为0，紧贴上方 */
}

.description-1{
  gap: 40px;
  padding: 8%;
}

.description-1-content{
  font-size: 24px;
  display: block;
  font-weight: 700;
  color: #63323C;
  font-size: 72px;
}

.description-1-img{
  width: 90%;
}

.description-2{
  gap: 40px;
  padding: 8%;
  background-color: white;
}

.description-2-table{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.description-2-content{
  font-size: 24px;
  display: block;
  font-weight: 700;
  color: #63323C;
  font-size: 72px;
}

.advertisement-top-image {
  width: 350px;
  height: 350px;
  object-fit: cover;
  margin-left: 4%;
}

.advertisement-content {
  max-width: 500px;
}

.advertisement-text {
  margin-bottom: 20px;
  width: 100%; /* 确保宽度与介绍文本一致 */
  position: relative; /* 确保子元素的绝对定位以此元素为参考 */
}

.bracket {
  font-size: 40px; /* 调整括号的大小 */
}

.left-bracket {
  position: absolute;
  color: #63323C;
  top: 0;
  left: -40px; /* 根据需要调整距离 */
}

.right-bracket {
  color: #63323C;
  position: absolute;
  right: -30px; /* 根据需要调整距离 */
  bottom: 0; /* 让括号的底部对齐 */
}

.advertisement-title {
  display: inline-block;
  font-size: 72px; /* 增大字体 */
  margin-bottom: 30px;
  letter-spacing: 30px;
  font-weight: 700;
  color: #63323C;
  text-align: center; /* 居中对齐 */
}

.advertisement-description {
  text-align: justify;
  display: block;
  font-size: 16px;
  letter-spacing: 3px;
  color: #63323C;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: 'Droid Sans';
}

.plans {
  text-align: center;
  padding: 50px 0;
}

.plans h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.plans p {
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 500;
}

.plan-options {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.plan {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  text-align: left;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  transition: box-shadow 0.3s ease; /* 添加过渡效果 */
}

.plan:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 鼠标悬停时的阴影效果 */
}

.plan h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.plan .price {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 500;
}

.plan button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}

.plan button:hover {
  background-color: #0056b3;
}

.plan button:disabled {
  background-color: #f8d7da;
  color: #dc3545;
  cursor: not-allowed;
}

.plan ul {
  list-style: none;
  padding: 0;
}

.plan li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.plan .icon {
  margin-right: 8px;
  width: 20px; /* 统一图标宽度 */
  height: 20px; /* 统一图标高度 */
}

.plan .check {
  fill: green;
}

.plan .error {
  fill: red;
}

.plan .info {
  fill: blue;
  cursor: pointer;
}

.plan .tooltip {
  margin-left: 10px;
  position: relative;
  display: inline-block;
}

.plan .tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* 位置可以根据需要调整 */
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.free-button{
  background-color: #DAD2BE;
  position: relative;
  color: #63323C;
  top: 40px;
  left: -150px;
  border: none;
  font-size: 16px;
  padding: 16px 24px 16px 24px;
  border-radius: 90px;
  cursor: pointer;
  line-height: 16px;
  margin-bottom: 20px;
  width: 192px;
  height: 48px;
  font-weight: 700;
}

.plan .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.plan .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.plan.premium {
  border: 2px solid #007bff;
  padding: 18px;
}

.plan.premium.logged-in button {
  background-color: #f8d7da;
  color: #dc3545;
  font-weight: 500;
}

.most-popular {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
}

.advertisement-title-container{
  position:relative;
}
.plans li {
  font-size: 14px;
}

/* 平板端的竖向显示 */
@media screen and (max-width: 960px) {
  .advertisement {
    flex-direction: column;
    text-align: center;
    margin-top: 0; /* 修改为0，紧贴上方 */
    gap: 20px;
  }

  .advertisement-top-image {
    margin-left: 0;
  }

  .advertisement-title {
    letter-spacing: 10px;
  }

  .advertisement-description {
    letter-spacing: 1px;
    font-size: 14px;
  }
}

@media screen and (min-width: 960px) {
  .advertisement {
    margin-top: 0; /* 修改为0，紧贴上方 */
  }
}

.el-carousel__container {
  width: 100%;
  height: 100%;
}

</style>
