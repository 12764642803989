/*
存放所有API的接口地址
*/

const base = {
    baseURL : 'http://y67sa23.inkmaster.cn/api/inkmaster',           // 服务器测试地址
    timeout : 10000,                             // 请求超时时间
    province: '/institution/province/list',                      // 获取省份列表
    institution_info: '/institution/list',    // 获取院校列表
    institution_by_province: '/institution/province', // 根据省份获取院校列表
    get_question: '/question',         // 获取题目
    get_draft: '/draft/get_draft',  // 获取草稿
    draft_ws: 'ws://y67sa23.inkmaster.cn/api/inkmaster/draft/interactive/',
    login: '/user/login/phone',              // 登录
    sms_send: '/user/sms-send',        // 发送短信验证码
    user_info: '/user',                 // 获取用户信息
    get_question_progress: '/get_question_progress', //获取某个题目的进度，同时也可以用于更新进度
    set_question_progress: '/set_question_progress', //设置某个题目的进度
    is_favourite: '/is_favourite',         // 获取是否收藏
    set_favourite: '/set_favourite',           // 创建收藏
    delete_favourite: '/delete_favourite',        // 删除收藏
    get_bank_list: '/question-bank',    // 获取题库列表
    get_bank: '/question-bank/',        // 获取题库
    get_question_batch: '/question_batch', // 批量获取题目
    get_question_list_by_bank_id: '/question_list', // 根据题库ID获取题目列表，需要翻页参数以及bank_id
    get_bank_progress: '/get_bank_progress', // 获取题库进度
    set_bank_progress: '/set_bank_progress', // 设置题库进度(更新或者创建)
    qq_login_path: '/user/login/qq/path',
    qq_login: '/user/login/qq',
    captcha_verify: '/user/captcha-verify',
    update_password: '/user/update/password',
    update_institution: '/user/update/institution',
    update_username: '/user/update/username',
    update_phone: '/user/update/phone',
    login_with_password: '/user/login/username',
    draft_bg:'/user/draft-bg',
    oss_sts_token: '/oss/sts_token',
    delete_draft_bg: '/user/draft-bg/delete',
    bind_qq: '/user/binding/qq',
    get_provider: '/user/provider',
    get_daily_exercise: '/daily_exercise',
}

if (process.env.NODE_ENV === 'production') {
    base.baseURL = 'https://www.inkmaster.cn/api/inkmaster'
    base.draft_ws = 'wss://www.inkmaster.cn/api/inkmaster/draft/interactive/'
}

if (process.env.NODE_ENV === 'local') {
    base.baseURL = 'http://localhost:9876/inkmaster'
    base.draft_ws = 'ws://localhost:9876/inkmaster/draft/interactive/'
}

export default base