<template>
  <nav class="top-nav-bar">
    <div class="nav-left">
      <div class="logo">
        <router-link to="/"><img src="../assets/logo/logo.png" alt="logo" style="width: 50px;"></router-link>
      </div>
      <div class="divider"></div>
      <ul class="nav-links">
        <li><router-link to="/">主页</router-link></li>
        <li><router-link to="/bank-list">题库</router-link></li>
        <li><router-link to="/daily-question-calendar">每日刷题</router-link></li>
        <!-- <li><router-link to="/about">关于我们</router-link></li> -->
      </ul>
    </div>
    <div class="user-icon" @mouseenter="showUserMenu" @mouseleave="hideUserMenu">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-yonghu"></use>
      </svg>
      <div v-if="isUserMenuVisible" class="user-menu">
        <template v-if="isLoggedIn">
          <div class="user-name" v-html="userName"></div>
          <div class="menu-item" @click="toUserInfo">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-yonghu"></use>
            </svg>
            我的信息
          </div>
          <div class="menu-item" @click="toSettings">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shezhi"></use>
            </svg>
            草稿设置
          </div>
          <!-- <div class="menu-item" @click="toMyBank">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tiku"></use>
            </svg>
            我的题库
          </div>
          <div class="menu-item" @click="toMyStar">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shoucang"></use>
            </svg>
            我的收藏
          </div>
          <div class="menu-item" @click="toStatistics">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tongjijisuan"></use>
            </svg>
            答题统计
          </div> -->
          <div class="menu-item" @click="logout">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-Frame"></use>
            </svg>
            Log out
          </div>
        </template>
        <template v-else>
          <div class="menu-item" @click="goToLogin">去登录</div>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TopNavBar',
  data() {
    return {
      isUserMenuVisible: false,
      isLoggedIn: false,
      userName: '', // 用户名
      nickName: '' // 用户昵称
    };
  },
  mounted() {
    this.checkLoginStatus();
  },
  methods: {
    showUserMenu() {
      this.isUserMenuVisible = true;
    },
    hideUserMenu() {
      this.isUserMenuVisible = false;
    },
    checkLoginStatus() {
      const token = localStorage.getItem('token');
      if (token) {
        this.isLoggedIn = true;
        this.userName = localStorage.getItem('userName');
        this.nickName = localStorage.getItem('nickName');
      } else {
        this.isLoggedIn = false;
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userAvatar');
      localStorage.removeItem('userName');
      localStorage.removeItem('nickName');
      this.isLoggedIn = false;
      this.isUserMenuVisible = false;
    },
    goToLogin() {
      this.$router.push('/login');
    },
    toUserInfo() {
      this.$router.push('/user/info');
    },
    toMyBank() {
      this.$router.push('/user/my-bank');
    },
    toMyStar() {
      this.$router.push('/user/my-star');
    },
    toStatistics() {
      this.$router.push('/user/statistics');
    },
    toSettings(){
      this.$router.push('/user/draft-settings');
    }
  }
};
</script>

<style scoped>
.top-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  height: 75px;
}

.nav-left {
  display: flex;
  position:absolute;
  left: 10%;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-right: 20px;
}

.logo svg {
  margin-right: 10px;
}

.divider {
  width: 1px;
  height: 30px;
  background-color: #ccc;
  margin-right: 20px;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  text-decoration: none;
  color: #777E90;
  font-size: 1em;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #000;
}

.user-icon {
  font-size: 1.5em;
  color: #555;
  cursor: pointer;
  position: absolute;
  right: 8%;
}

.user-icon svg {
  width: 24px;
  height: 24px;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1001;
  width: 200px;
  font-size: 16px;
  text-align: left; /* 调整为左对齐 */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.user-name {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: left; /* 调整为左对齐 */
  margin-left: 10px; /* 增加左边距 */
  margin-top: 30px;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: 600;
  border-bottom: #F1F2F4 solid 1px;
  transition: background-color 0.3s;
  color: #777E90;
  margin-left: 10px; 
  padding-bottom: 13px;
  font-family: 'DM Sans';
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.menu-item svg {
  margin-right: 10px;
}


.logout {
  color: red;
}
</style>
