import axios from "../utils/requests.js";       //引入封装好的axios
import base from "./base.js";                   //引入接口列表

const api = {
    async getQuestion(id){
        return axios.get(base.baseURL + base.get_question + "/" + id)
    },
    async getDraft(params){
        return axios.post(base.baseURL + base.get_draft, params)
    },
    async login(data){
        return axios.post(base.baseURL + base.login, data)
    },
    async smsSend(data){
        return axios.post(base.baseURL + base.sms_send, data)
    },
    async userInfo(){
        return axios.get(base.baseURL + base.user_info)
    },
    // 传递题目的id，获取进度
    async questionProgress(id){
        return axios.get(base.baseURL + base.get_question_progress + "?bq_id=" + id)
    },
    // 传递题目的id和进度，更新进度
    async questionProgressUpdate(data){
        return axios.post(base.baseURL + base.set_question_progress, data)
    },
    // 根据bq_id_list批量获取是否收藏
    async favouriteListByUserId(params){
        return axios.post(base.baseURL + base.is_favourite, params)
    },
    // 获取草稿背景
    async getDraftBg(){
        return axios.get(base.baseURL + base.draft_bg)
    },
    // 设置草稿背景
    async setDraftBg(data){
        return axios.post(base.baseURL + base.draft_bg, data)
    },
    // 创建收藏
    async favouriteCreate(params){
        return axios.post(base.baseURL + base.set_favourite, params)
    },
    // 删除收藏
    async favouriteDelete(params){
        return axios.post(base.baseURL + base.delete_favourite, params)
    },
    // 获取题库列表
    async getBankList(page, pageSize){
        return axios.get(base.baseURL + base.get_bank_list + "?page=" + page + "&page_size=" + pageSize)
    },
    // 获取题库数据
    async getBank(id){
        return axios.get(base.baseURL + base.get_bank + id)
    },
    // 根据题库ID获取题目列表，需要翻页参数以及bank_id,post请求，携带json过去
    async getQuestionListByBankId(params){
        return axios.post(base.baseURL + base.get_question_list_by_bank_id, params)
    },
    async getQuestionBatch(params){
        return axios.post(base.baseURL + base.get_question_batch, params)
    },
    // 获取题库进度
    async getBankProgress(id){
        return axios.get(base.baseURL + base.get_bank_progress + "?bank_id=" + id)
    },
    // 设置题库进度(更新或者创建)
    async setBankProgress(data){
        return axios.post(base.baseURL + base.set_bank_progress, data)
    },
    // 获取qq登录路径
    async qqLoginPath(){
        return axios.get(base.baseURL + base.qq_login_path)
    },
    // qq登录
    async qqLogin(data){
        return axios.post(base.baseURL + base.qq_login, data)
    },
    // 图形验证码校验
    async captchaVerify(data){
        return axios.post(base.baseURL + base.captcha_verify, data)
    },
    // 修改密码
    async updatePassword(data){
        return axios.post(base.baseURL + base.update_password, data)
    },
    // 修改用户目标院校
    async updateUserTargetInstitution(data){
        return axios.post(base.baseURL + base.update_institution, data)
    },
    // 获取院校列表
    async getInstitutionList(page, pageSize){
        return axios.get(base.baseURL + base.institution_info + "?page=" + page + "&page_size=" + pageSize)
    },
    // 修改用户名
    async updateUsername(data){
        return axios.post(base.baseURL + base.update_username, data)
    },
    // 修改手机号
    async updatePhone(data){
        return axios.post(base.baseURL + base.update_phone, data)
    },
    // 通过用户名登录
    async loginWithPassword(data){
        return axios.post(base.baseURL + base.login_with_password, data)
    },
    // 获取省份列表
    async getProvinceList(page_num, page_size){
        return axios.get(base.baseURL + base.province + "?page_num=" + page_num + "&page_size=" + page_size)
    },
    // 根据省份获取院校列表
    async getInstitutionListByProvince(province_id){
        return axios.get(base.baseURL + base.institution_by_province + "?province_id=" + province_id)
    },
    // 获取oss的sts_token
    async getOssStsToken(){
        return axios.get(base.baseURL + base.oss_sts_token)
    },
    // 删除草稿背景
    async deleteDraftBg(){
        return axios.post(base.baseURL + base.delete_draft_bg)
    },
    // 绑定qq
    async bindQQ(data){
        return axios.post(base.baseURL + base.bind_qq, data)
    },
    // 获取用户绑定的第三方登录信息
    async getProvider(provider){
        return axios.get(base.baseURL + base.get_provider + "?provider=" + provider)
    },
    // 获取每日一练
    async getDailyExercise(data){
        return axios.post(base.baseURL + base.get_daily_exercise, data)
    }
}

export default api