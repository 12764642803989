import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { show:true },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/scratch-paper',
    name: 'scratch-paper',
    component: () => import('../views/ScratchPaperView.vue')
  },
  {
    path: '/question-list',
    name: 'question-list',
    component: () => import('../views/QuestionListView.vue')
  },
  {
    path: '/bank-list',
    name: 'bank-list',
    meta: { show:true },
    component: () => import('../views/BankListView.vue')
  },
  {
    path: '/discover',
    name: 'discover',
    meta: { show:true },
    component: () => import('../views/DiscoverView.vue')
  },
  {
    path:'/about',
    name: 'about',
    meta: { show:true},
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/daily-question-calendar',
    name: 'daily-question-calendar',
    meta: { show:true },
    component: () => import('../views/DailyQuestionCalendarView.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: { show:true },
    component: () => import('../views/UserView/UserView.vue'),
    children:[
      {
        path:'info',
        name:'info',
        component: () => import('../views/UserView/ChildrenView/UserInfoView.vue')
      },
      {
        path:'draft-settings',
        name: 'draft-settings',
        component: () => import('../views/UserView/ChildrenView/DraftSettingsView.vue')
      },
      {
        path:'my-bank',
        name:'my-bank',
        component: () => import('../views/UserView/ChildrenView/MyBankView.vue')
      },
      {
        path:'my-star',
        name:'my-star',
        component: () => import('../views/UserView/ChildrenView/MyStarView.vue')
      },
      {
        path:'statistics',
        name:'statistics',
        component: () => import('../views/UserView/ChildrenView/StatisticsView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
