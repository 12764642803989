<template>
  <div id="app">
    <TopNavBar v-show="this.$route.meta.show" :key="navKey"/>
    <div class="main">
      <router-view/>
    </div>
  </div>
</template>

<script>
import TopNavBar from './components/TopNavBar.vue';
import EventBus from './utils/eventBus.js';
import api from './api/index.js';
import { ref, onMounted } from 'vue';
import VConsole from 'vconsole';

if(process.env.NODE_ENV === 'production'){
  console.log("production");
}else{
  const vConsole = new VConsole();
}

export default {
  name: 'App',
  components: {
    TopNavBar,
  },
  setup() {
    const navKey = ref(0);

    const refreshNavBar = () => {
      navKey.value += 1;
    };

    const getUserInfo = async () => {
      try {
        const res = await api.userInfo();
        if (res.status !== 200) {
          this.$message({
            message: '获取用户信息失败',
            type: 'error'
          });
          return;
        }
        if (res.data.code === 0) {
          localStorage.setItem('userInfo', res.data.data)
          localStorage.setItem('userAvatar', res.data.data.avatar);
          localStorage.setItem('userName', res.data.data.user_name);
          localStorage.setItem('nickName', res.data.data.nick_name);
        }
        refreshNavBar();
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      getUserInfo();
    });

    EventBus.on('user-logged-in', getUserInfo);

    return {
      navKey,
      getUserInfo
    };
  }
};
</script>

<style>
body {
  touch-action: manipulation;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main {
  margin-top: 75px;
  padding: 20px;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
